import { useRef, useState, useEffect, useCallback } from 'react';
import useDebounce from '../../../../../utils/custom-hooks';
import Search from '../../../../../images/Serch.svg';
import Close from '../../../../../images/close-x-sign-svg.svg';
import Analytics from '../../../../../images/Align-box--middle-left.svg';
import Training from '../../../../../images/Align-box-training-middle-left.svg';
import Company from '../../../../../images/our-team/tuiIconCompanyLarge.svg';
import Loader from '../../../../../images/tube-spinner.svg';

import styles from './input-search.module.css';
import { NavLink } from 'react-router-dom';

function SearchInput({ setOpenSearch, setOpenMenu }) {
    const [value, setValue] = useState('');
    const [data, setData] = useState([]);
    const debouncedSearchTerm = useDebounce(value, 500);

    const [loading, setLoading] = useState(false);

    const inputRef = useRef(null);

    const clearValue = () => {
        setValue('');
        setData([]);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const searchLinkPress = useCallback(() => {
        if (window.ym) {
            window.ym(96742836, 'reachGoal', 'search_relevant');
        }
    }, []);

    const searchEntry = useCallback(() => {
        if (window.ym) {
            window.ym(96742836, 'reachGoal', 'search');
        }
    }, []);

    const onChangeHandlerSearch = (e) => {
        setValue(e.target.value);
        searchEntry();
    };

    const closeSearchAndMobileMenu = () => {
        searchLinkPress();
        setOpenMenu(false);
        setOpenSearch(false);
    };

    const getData = (value) => {
        if (value.trim() === '') {
            setLoading(false);
            clearValue();
            return;
        }

        setLoading(true);
        fetch(`api/search/?query=${value}`)
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            getData(value);
        } else {
            clearValue();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className={styles.search}>
            <div className={styles.icon}>
                <img src={Search} alt="search" />
            </div>
            <input
                ref={inputRef}
                value={value}
                onChange={onChangeHandlerSearch}
                type="text"
                placeholder="Поиск"
                className={styles.input}
            />
            <div className={styles.text} onClick={clearValue}>
                Очистить поиск
            </div>
            <div className={styles.close} onClick={() => setOpenSearch(false)}>
                <img src={Close} alt="close" />
            </div>

            {loading && (
                <ul className={styles.list}>
                    <div className={styles.loader}>
                        <img src={Loader} alt="loader" />
                    </div>
                </ul>
            )}
            {data.length > 0 && (
                <ul className={styles.list}>
                    {data.map((element) => (
                        <li className={styles.item} key={element.id}>
                            {element.module === 'Аналитика и новости' ? <img src={Analytics} alt="analytics" /> : null}
                            {element.module === 'Обучение' ? <img src={Training} alt="training" /> : null}
                            {element.module === 'Инфо' ? <img src={Company} alt="company" /> : null}

                            <NavLink
                                onClick={closeSearchAndMobileMenu}
                                className={styles.link}
                                to={`${
                                    element.module === 'Аналитика и новости'
                                        ? '/analytics-and-news/'
                                        : element.module === 'Обучение'
                                          ? '/education/'
                                          : element.module === 'Инфо'
                                            ? '/info/'
                                            : ''
                                }${element.slug}`}
                            >
                                <span className={styles.name}>{element.name}</span>
                                <span className={styles.module}>{element.module}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}

            {!loading && data.length === 0 && (
                <ul style={value === '' ? { display: 'none' } : null} className={styles.list}>
                    <div className={styles.notFound}>Ничего не найдено</div>
                </ul>
            )}
        </div>
    );
}

export default SearchInput;
