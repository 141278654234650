import { useRef } from 'react';
import Close from '../../../../../images/close-black-icon.svg';
import Comments from '../../../../../images/comments.webp';
import styles from './pop-up-timeout-analitycs.module.css';

export const PopUpTimeoutAnalitycs = ({ setShowTimeoutPopupFirst }) => {
    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closePopupForImgCLick();
        }
    };

    const closePopupForImgCLick = () => {
        setShowTimeoutPopupFirst(false);
        if (window.ym) window.ym(96742836, 'reachGoal', 'popup-our-analysis-close');
    };

    const clickForBtn = () => {
        setShowTimeoutPopupFirst(false);
        if (window.ym) window.ym(96742836, 'reachGoal', 'popup-our-analysis-lead');
    };

    return (
        <div onClick={handleClickOutside} className={styles.wrapper}>
            <div ref={modalRef} className={styles.popup}>
                <div onClick={closePopupForImgCLick} className={styles.close}>
                    <img src={Close} alt="close" />
                </div>
                <div className={styles.container}>
                    <h2 className={styles.title}>Наша аналитика позволяет обгонять индекс вдвое</h2>
                    <h3 className={styles.subTitle}>
                        Подписывайтесь на наш телеграм канал, чтобы не упустить важную информацию
                    </h3>
                    <div>
                        <img className={styles.commentsImg} src={Comments} alt="Комментарии" />
                    </div>
                    <a
                        onClick={clickForBtn}
                        href={'https://t.me/+Avs2bsQ5mQk4N2My'}
                        className={styles.btn}
                        target={'_blank'}
                        rel="noopener noreferrer"
                    >
                        Подписаться
                    </a>
                </div>
            </div>
        </div>
    );
};
