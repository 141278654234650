import { useCallback, useEffect, useState, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import {
    TwoLevelMenu,
    TwoLevelMenuOther,
    TwoLevelMenuOtherProfile,
    TwoLevelMenuTariffs,
} from './two-level-menu/two-level-menu';
import { extraining, portfel, products, profile } from '@utils/two-level-menu-data';
import { useActiveButtonCountryTagChoice } from '@services/context/country-filter-context';
import { setAuthChecked } from '@services/reducers/user-slice';
import doorExit from '@images/door-exit.svg';
import InvestEraWideLogo from '@images/ie-logo.svg';
import InvestEraSmallLogo from '@images/logo-logo.svg';
import SearchInput from './input-search/input-search';
import ActiveSunSvg from '@images/active-sun-icon.svg';
import SunSvg from '@images/sun-icon.svg';
import ActiveMoonSvg from '@images/active-moon-icon.svg';
import MoonSvg from '@images/moon-icon.svg';
import SearchLogo from '@images/Serch.svg';
import Earth from '@images/internet.svg';
import ArrowDown from '@images/arrows/arrow-down.svg';
import ArrowUp from '@images/arrows/arrow-up.svg';
import Close from '@images/close-x-sign-svg.svg';
import UserLogo from '@images/user.svg';
import { useTheme } from '@services/hooks/use-theme';
import styles from './app-header-main.module.css';

function AppHeaderMain() {
    const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
    const value = useActiveButtonCountryTagChoice();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const headerRef = useRef(null);
    const { theme, toggleTheme } = useTheme();
    const [openSearch, setOpenSearch] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [isOpenMenuHoveredTraining, setIsOverMenuHoveredTraining] = useState(false);
    const [isOpenMenuHoveredPortfolio, setIsOverMenuHoveredPortfolio] = useState(false);
    const [isOpenMenuHoveredProfile, setIsOverMenuHoveredProfile] = useState(false);
    const [isOpenMenuHoveredTariffs, setIsOverMenuHoveredTariffs] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState({
        profile: true,
        training: false,
        trainingSubBeginner: false,
        trainingSubExpert: false,
        trainingSubAdvanced: false,
        portfel: false,
        tariffs: false,
    });

    const token = localStorage.getItem('token');

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
        if (!openMenu) {
            onClickHandlerYM('/menu-open');
            document.body.style.overflow = 'hidden';
        } else {
            onClickHandlerYM('/menu-close');
            document.body.style.overflow = '';
        }
    };

    const navigateProfile = useCallback(() => {
        onClickHandlerYM('/entry');
        if (!token) {
            navigate('/authorization');
        } else {
            navigate('/profile');
        }
    }, []);

    const logout = useCallback(() => {
        onClickHandlerYM('/quit');
        value.setPopUpExitVision((prev) => !prev);
    }, []);

    useEffect(() => {
        // Проверяем, есть ли токен в localStorage
        const token = localStorage.getItem('token');
        // Если токен есть, то isAuthChecked должно быть true
        if (token) {
            dispatch(setAuthChecked(true));
        } else {
            // Если токен нет, то isAuthChecked должно быть false
            dispatch(setAuthChecked(false));
        }
    }, [isAuthChecked]);

    const toggleTwoLevelMenuPortfel = () => {
        setIsOverMenuHoveredPortfolio(true);
        setIsOverMenuHoveredTraining(false);
        setIsOverMenuHoveredProfile(false);
        setIsOverMenuHoveredTariffs(false);
    };

    const toggleTwoLevelMenuTariffs = () => {
        setIsOverMenuHoveredTariffs(true);
        setIsOverMenuHoveredPortfolio(false);
        setIsOverMenuHoveredTraining(false);
        setIsOverMenuHoveredProfile(false);
    };

    const toggleTwoLevelMenuProfile = () => {
        setIsOverMenuHoveredProfile(true);
        setIsOverMenuHoveredPortfolio(false);
        setIsOverMenuHoveredTraining(false);
        setIsOverMenuHoveredTariffs(false);
    };

    const closeAllTogglemenu = () => {
        setIsOverMenuHoveredTariffs(false);
        setIsOverMenuHoveredTraining(false);
        setIsOverMenuHoveredPortfolio(false);
        setIsOverMenuHoveredProfile(false);
    };

    const toggleSubMenu = (type) => {
        setOpenSubMenu((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    //временное решение
    const toggleOpenClosemenu = () => {
        setOpenMenu((prevState) => !prevState);
        if (openMenu) {
            setOpenMenu(false);
            document.body.style.overflow = '';
        }
    };

    const closeMenuForLogOut = () => {
        if (isAuthChecked) {
            logout();
        } else {
            navigateProfile();
        }
        toggleOpenClosemenu();
    };

    //закрытие окон если курсор вне header
    useEffect(() => {
        let timeoutId;

        const handleClickOutside = (event) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                if (headerRef.current && !headerRef.current.contains(event.target)) {
                    closeAllTogglemenu();
                }
            }, 100);
        };

        document.addEventListener('mousemove', handleClickOutside);
        return () => {
            document.removeEventListener('mousemove', handleClickOutside);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    //close Modal for openSearch
    const closeSearch = () => {
        onClickHandlerYM('/search');
        setOpenSearch((open) => !open);
        closeAllTogglemenu();
    };

    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    const onClickChangeTheme = () => {
        toggleTheme();
        if (window.ym) {
            if (theme === 'app_light_theme') window.ym(96742836, 'reachGoal', `clck-hdr-drk`);
            if (theme === 'app_dark_theme') window.ym(96742836, 'reachGoal', `clck-hdr-lght`);
        }
    };

    const onClickHandlerYM = (href) => {
        if (window.ym) {
            if (href === '/') window.ym(96742836, 'reachGoal', `clck-hdr-mn`);
            if (href === '/logo') window.ym(96742836, 'reachGoal', `clck-hdr-logo`);
            if (href === '/analytics-and-news') window.ym(96742836, 'reachGoal', `clck-hdr-an`);
            if (href === '/market-map') window.ym(96742836, 'reachGoal', `clck-hdr-mrkt_map`);
            if (href === '/education') window.ym(96742836, 'reachGoal', `clck-hdr-educ`);
            if (href === '/polezno-investoru') window.ym(96742836, 'reachGoal', `clck-hdr-invstr`);
            if (href === '/glossary') window.ym(96742836, 'reachGoal', `clck-hdr-glsr`);
            if (href === '/about-us') window.ym(96742836, 'reachGoal', `clck-hdr-abt`);
            if (href === '/profile-icon') window.ym(96742836, 'reachGoal', `clck-hdr-prfl-icon`);
            if (href === '/tables') window.ym(96742836, 'reachGoal', `clck-hdr-tbls`);
            if (href === 'Ideas РФ') window.ym(96742836, 'reachGoal', `clck-hdr-tbls-ids_rus`);
            if (href === 'Ideas World') window.ym(96742836, 'reachGoal', `clck-hdr-tbls-ids_wrld`);
            if (href === 'Unity РФ') window.ym(96742836, 'reachGoal', `clck-hdr-tbls-unt_rus`);
            if (href === 'Unity World') window.ym(96742836, 'reachGoal', `clck-hdr-tbls-unt_wrld`);
            if (href === '/profile-link') window.ym(96742836, 'reachGoal', `clck-hdr-prfl-link`);
            if (href === '/globus') window.ym(96742836, 'reachGoal', `clck-hdr-glbs`);
            if (href === '/quit') window.ym(96742836, 'reachGoal', `clck-hdr-quit`);
            if (href === '/entry') window.ym(96742836, 'reachGoal', `clck-hdr-entry`);
            if (href === '/search') window.ym(96742836, 'reachGoal', `clck-hdr-srch`);
            if (href === '/profile-cab') window.ym(96742836, 'reachGoal', `clck-hdr-prfl-cbnt`);
            if (href === '/menu-open') window.ym(96742836, 'reachGoal', `clck-hdr-menu-open`);
            if (href === '/menu-close') window.ym(96742836, 'reachGoal', `clck-hdr-menu-close`);
            if (href === '/screener') window.ym(96742836, 'reachGoal', `clck-hdr-scrnr`);
            if (href === '/tariffs-header') window.ym(96742836, 'reachGoal', `clck-hdr-trfs`);
            if (href === 'tariffs') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-drpdwn`);
            if (href === 'products/ideas-russia') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-ids_rus-drpdwn`);
            if (href === 'products/ideas-world') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-ids_wrld-drpdwn`);
            if (href === 'products/ideas-all') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-ids_all-drpdwn`);
            if (href === 'products/unity-russia') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-unt_rus-drpdwn`);
            if (href === 'products/unity-world') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-unt_wrld-drpdwn`);
            if (href === 'products/unity-all') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-unt_all-drpdwn`);
            if (href === 'products/premium') window.ym(96742836, 'reachGoal', `clck-hdr-trfs-prm-drpdwn`);
        }
    };

    return !isMobile ? (
        <header ref={headerRef} className={styles.header}>
            <div className={styles.container}>
                <NavLink to={'/'} className={styles.link} onClick={() => onClickHandlerYM('/logo')}>
                    <img className={styles.logo} src={InvestEraWideLogo} alt="logo" />
                </NavLink>

                {openSearch && <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu} />}
                {!openSearch && (
                    <ul className={styles.textContainer}>
                        <div className={styles.headerUp}>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink to={`/`} className={styles.link} onClick={() => onClickHandlerYM('/')}>
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Главная</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink
                                    to={`/analytics-and-news`}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/analytics-and-news')}
                                >
                                    <button className={styles.buttonHeader} type={'submit'}>
                                        <span className={styles.text}>Аналитика и новости</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink
                                    to={`/market-map`}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/market-map')}
                                >
                                    <button className={styles.buttonHeader} type={'submit'}>
                                        <span className={styles.text}>Карта рынка</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu} onClick={closeAllTogglemenu}>
                                <NavLink
                                    to={'/education'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/education')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Обучение</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={toggleTwoLevelMenuPortfel} onClick={closeAllTogglemenu}>
                                <NavLink
                                    to={'/tables/ideas-russia'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/tables')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Портфели</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu} onClick={closeAllTogglemenu}>
                                <NavLink
                                    to={'/screener'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/screener')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Акции</span>
                                    </button>
                                </NavLink>
                            </li>
                        </div>

                        <div className={`${styles.headerUp} ${styles.headerUpDown}`}>
                            <li onMouseEnter={toggleTwoLevelMenuTariffs} onClick={closeAllTogglemenu}>
                                <NavLink
                                    to={'/tariffs'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/tariffs-header')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Тарифы</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={toggleTwoLevelMenuProfile} onClick={closeAllTogglemenu}>
                                <NavLink
                                    to={'/profile'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/profile-link')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text}>Профиль</span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink
                                    to={`/polezno-investoru`}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/polezno-investoru')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text} style={{ whiteSpace: 'nowrap' }}>
                                            Полезно инвестору
                                        </span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink
                                    to={'/glossary'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/glossary')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text} style={{ whiteSpace: 'nowrap' }}>
                                            Глоссарий
                                        </span>
                                    </button>
                                </NavLink>
                            </li>
                            <li onMouseEnter={closeAllTogglemenu}>
                                <NavLink
                                    to={'/about-us'}
                                    className={styles.link}
                                    onClick={() => onClickHandlerYM('/about-us')}
                                >
                                    <button className={styles.buttonHeader}>
                                        <span className={styles.text} style={{ whiteSpace: 'nowrap' }}>
                                            О нас
                                        </span>
                                    </button>
                                </NavLink>
                            </li>
                        </div>
                    </ul>
                )}

                <div className={styles.authorizationWrapper}>
                    <div
                        className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}
                        onMouseEnter={closeAllTogglemenu}
                    >
                        <img
                            onClick={onClickChangeTheme}
                            className={styles.themeImg}
                            src={theme === 'app_light_theme' ? ActiveSunSvg : SunSvg}
                            alt="sun"
                        />
                        <img
                            onClick={onClickChangeTheme}
                            className={styles.themeImg}
                            src={theme === 'app_dark_theme' ? ActiveMoonSvg : MoonSvg}
                            alt="moon"
                        />
                    </div>
                    <div className={styles.searchLogo}>
                        <img src={SearchLogo} alt="search" onClick={closeSearch} />
                    </div>
                    {isAuthChecked && (
                        <NavLink
                            to={'/profile'}
                            className={styles.userLogo}
                            onClick={() => onClickHandlerYM('/profile-icon')}
                        >
                            <img src={UserLogo} alt="user" />
                        </NavLink>
                    )}
                    {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
                        <button
                            onClick={() => {
                                value.toggleVisible(true);
                                onClickHandlerYM('/globus');
                            }}
                            className={styles.earth}
                        >
                            <img src={Earth} alt="earth" />
                        </button>
                    )}
                    {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
                        <div className={styles.buttonWrapper} onClick={isAuthChecked ? logout : navigateProfile}>
                            <div className={styles.doorExitWrapper}>
                                <img src={doorExit} className={styles.doorExit} alt={'exit'} />
                            </div>
                            <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
                        </div>
                    )}
                </div>
            </div>
            {isOpenMenuHoveredTraining && (
                <TwoLevelMenu open={false} data={extraining} closeModal={closeAllTogglemenu} />
            )}
            {isOpenMenuHoveredPortfolio && (
                <TwoLevelMenuOther open={false} data={portfel} closeModal={closeAllTogglemenu} />
            )}
            {isAuthChecked && isOpenMenuHoveredProfile && (
                <TwoLevelMenuOtherProfile open={false} data={profile} closeModal={closeAllTogglemenu} clazz={true} />
            )}
            {isOpenMenuHoveredTariffs && (
                <TwoLevelMenuTariffs open={false} data={products} closeModal={closeAllTogglemenu} />
            )}
        </header>
    ) : (
        <header className={`${styles.header} ${openMenu ? styles.open : ''}`}>
            <div className={styles.container}>
                <div className={styles.containerUp}>
                    <NavLink
                        to={'/'}
                        className={styles.link}
                        onClick={() => {
                            openMenu && toggleOpenClosemenu();
                            onClickHandlerYM('/logo');
                        }}
                    >
                        <img className={styles.logo} src={InvestEraSmallLogo} alt="logo" />
                    </NavLink>
                    {openSearch && <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu} />}
                    <div className={styles.containerDown}>
                        <div className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}>
                            <img
                                onClick={onClickChangeTheme}
                                className={styles.themeImg}
                                src={theme === 'app_light_theme' ? ActiveSunSvg : ActiveMoonSvg}
                                alt="sun"
                            />
                        </div>
                        <div className={`${styles.searchLogo} ${openSearch ? styles.hidden : ''}`}>
                            <img src={SearchLogo} alt="search" onClick={closeSearch} />
                        </div>
                        {isAuthChecked && (
                            <NavLink
                                to={'/profile'}
                                onClick={() => {
                                    openMenu && toggleOpenClosemenu();
                                    onClickHandlerYM('/profile-icon');
                                }}
                                className={`${styles.userLogo} ${openSearch ? styles.hidden : ''}`}
                            >
                                <img src={UserLogo} alt="user" />
                            </NavLink>
                        )}
                        {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
                            <button
                                onClick={() => {
                                    value.toggleVisible(true);
                                    onClickHandlerYM('/globus');
                                }}
                                className={styles.earth}
                                style={openSearch ? { display: 'none' } : {}}
                            >
                                <img src={Earth} alt="earth" />
                            </button>
                        )}
                        <div className={`${styles.menuText} ${openSearch ? styles.hidden : ''}`} onClick={toggleMenu}>
                            {openMenu ? <img src={Close} alt="close" /> : 'Меню'}
                        </div>
                    </div>
                </div>
                <div style={{ display: openMenu ? 'flex' : 'none' }} className={styles.down}>
                    <ul className={styles.list}>
                        <li className={styles.listItem}>
                            <div
                                className={styles.listItemImgWrapper}
                                onClick={() => {
                                    toggleSubMenu('profile');
                                }}
                            >
                                Профиль
                                <img src={openSubMenu.profile ? ArrowUp : ArrowDown} alt="arrow" />
                            </div>
                            {isAuthChecked ? (
                                <ul className={`${styles.subList} ${openSubMenu.profile ? '' : styles.hidden}`}>
                                    <li onClick={toggleOpenClosemenu} className={styles.subListItem}>
                                        <NavLink
                                            className={styles.linkMenu}
                                            to={'/profile'}
                                            onClick={() => onClickHandlerYM('/profile-cab')}
                                        >
                                            Личный кабинет
                                        </NavLink>
                                    </li>
                                    <li onClick={toggleOpenClosemenu} className={styles.subListItem}>
                                        <NavLink
                                            className={styles.linkMenu}
                                            to={'/webinars'}
                                            onClick={() => onClickHandlerYM('/webinars')}
                                        >
                                            Вебинары
                                        </NavLink>
                                    </li>
                                </ul>
                            ) : (
                                <ul className={`${styles.subList} ${openSubMenu.profile ? '' : styles.hidden}`}>
                                    <li className={styles.subListItem} onClick={toggleOpenClosemenu}>
                                        <NavLink
                                            className={styles.linkMenu}
                                            to="/authorization"
                                            onClick={() => onClickHandlerYM('/entry')}
                                        >
                                            Войти
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li onClick={toggleOpenClosemenu} className={styles.listItem}>
                            <NavLink
                                to={'/analytics-and-news'}
                                className={styles.linkMenu}
                                onClick={() => onClickHandlerYM('/analytics-and-news')}
                            >
                                Аналитика и новости
                            </NavLink>
                        </li>
                        <li onClick={toggleOpenClosemenu} className={styles.listItem}>
                            <NavLink
                                to={'/market-map'}
                                className={styles.linkMenu}
                                onClick={() => onClickHandlerYM('/market-map')}
                            >
                                Карта рынка
                            </NavLink>
                        </li>

                        <li
                            className={styles.listItem}
                            onClick={() => {
                                toggleSubMenu('portfel');
                            }}
                        >
                            <div className={styles.listItemImgWrapper}>
                                Портфели
                                <img src={openSubMenu.portfel ? ArrowUp : ArrowDown} alt="arrow" />
                            </div>
                            <ul className={`${styles.subList} ${openSubMenu.portfel ? '' : styles.hidden}`}>
                                {portfel?.map((item) => (
                                    <li key={item.id} className={styles.subListItem}>
                                        <NavLink
                                            onClick={() => {
                                                toggleOpenClosemenu();
                                                onClickHandlerYM(item.name);
                                            }}
                                            className={styles.linkMenu}
                                            to={`/tables/${item.navigate}`}
                                        >
                                            {item.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>

                        <li className={styles.listItem}>
                            <div className={styles.listItemImgWrapper}>
                                <NavLink
                                    onClick={() => {
                                        toggleOpenClosemenu();
                                        onClickHandlerYM('/screener');
                                    }}
                                    className={styles.linkMenu}
                                    to={`/screener`}
                                >
                                    Акции
                                </NavLink>
                            </div>
                        </li>
                        <li className={styles.listItem}>
                            <div className={styles.listItemImgWrapper}>
                                <NavLink
                                    onClick={() => {
                                        toggleOpenClosemenu();
                                        onClickHandlerYM('/tariffs-header');
                                    }}
                                    className={styles.linkMenu}
                                    to={`/tariffs`}
                                >
                                    Тарифы
                                </NavLink>
                                <img
                                    src={openSubMenu.tariffs ? ArrowUp : ArrowDown}
                                    alt="arrow"
                                    onClick={() => toggleSubMenu('tariffs')}
                                />
                            </div>
                            <ul className={`${styles.subList} ${openSubMenu.tariffs ? '' : styles.hidden}`}>
                                {products?.map(
                                    (item) =>
                                        item.name !== 'Тарифы' && (
                                            <li key={item.id} className={styles.subListItem}>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleOpenClosemenu();
                                                        onClickHandlerYM(item.navigate);
                                                    }}
                                                    className={styles.linkMenu}
                                                    to={`/${item.navigate}`}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        )
                                )}
                            </ul>
                        </li>
                        <li className={styles.listItem}>
                            <div className={styles.listItemImgWrapper}>
                                <NavLink
                                    onClick={() => {
                                        toggleOpenClosemenu();
                                        onClickHandlerYM('/education');
                                    }}
                                    className={styles.linkMenu}
                                    to={`/education`}
                                >
                                    Обучение
                                </NavLink>
                            </div>
                        </li>
                        <li onClick={toggleOpenClosemenu} className={styles.listItem}>
                            <NavLink
                                className={styles.linkMenu}
                                to={`/polezno-investoru`}
                                onClick={() => onClickHandlerYM('/polezno-investoru')}
                            >
                                Полезно инвестору
                            </NavLink>
                        </li>
                        <li onClick={toggleOpenClosemenu} className={styles.listItem}>
                            <NavLink
                                className={styles.linkMenu}
                                to={'/glossary'}
                                onClick={() => onClickHandlerYM('/glossary')}
                            >
                                Глоссарий
                            </NavLink>
                        </li>
                        <li onClick={toggleOpenClosemenu} className={styles.listItem}>
                            <NavLink
                                className={styles.linkMenu}
                                to={'/about-us'}
                                onClick={() => onClickHandlerYM('/about-us')}
                            >
                                О компании
                            </NavLink>
                        </li>
                    </ul>
                    <div className={styles.buttonWrapperMobile} onClick={closeMenuForLogOut}>
                        <div className={styles.doorExitWrapper}>
                            <img src={doorExit} className={styles.doorExit} alt={'exit'} />
                        </div>
                        <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
                    </div>
                </div>
            </div>

            <div className={styles.buttonWrapper} onClick={closeMenuForLogOut}>
                <div className={styles.doorExitWrapper}>
                    <img src={doorExit} className={styles.doorExit} alt={'exit'} />
                </div>
                <div className={styles.txtBtn}>{isAuthChecked ? 'Выйти' : 'Войти'}</div>
            </div>
        </header>
    );
}

export default AppHeaderMain;
